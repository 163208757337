import { useState, useEffect, useContext } from "react";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, Navigate } from "react-router-dom";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";

import { EasyContext } from "../context";
import { consts } from "../consts";
import background from "../assets/backgroundConfirmationAccount.png";
import imageConfirmationAccount from "../assets/imageConfirmationAccount.png";
import imageAlreadyConfirmationAccount from "../assets/alreadyConfirmationAccount.png";
import logo from "../assets/logo_videolink_mobile.svg";
import companyLogoWhite from "../assets/svg/omniflou-white.svg";
import i18n from "../i18n";
import service from "../service";
import { environment } from "../config";

function ConfirmationAccount() {
  const theme = useTheme();
  const mobileBreakpoint = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setUser, state } = useContext(EasyContext);
  const [lang] = useState(state.lang);
  const [tokenStatus, setTokenStatus] = useState();
  const params = useParams();

  const [height, setHeight] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setHeight(window.innerHeight);
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  useEffect(() => {
    async function validate() {
      await validateToken({ token: params.token });
    }
    validate();
  }, [params]);

  return mobileBreakpoint ? (
    tokenStatus?.dataResponse?.msg === consts.status_token_already_exist ? (
      <Box
        style={{
          height: "100vh",
          background:
            "linear-gradient(153.91deg, #E53463 0.87%, #4B27EF 83.55%)",
          overflow: "hidden",
          backgroundSize: "cover",
          display: "flex",
          flexDirection: "column",
          justifyContent: height > 800 && "center",
          overflow: "scroll",
          alignItems: "center",
          paddingTop: height <= 800 ? 75 : 24,
          paddingBottom: height <= 800 && 75,
        }}
      >
        <Box
          style={{
            fontFamily: "Rubik",
            fontWeight: 400,
            fontSize: 40,
            color: "#FFFFFF",
          }}
        >
          {t("notification.account.already.exist")}
        </Box>
        <Box
          style={{
            marginTop: 17,
          }}
        >
          <a href={environment.adminURL} style={{ textDecoration: "none" }}>
            <Button
              variant="outlined"
              style={{
                textTransform: "initial",
                borderRadius: 20,
                fontSize: 14,
                color: "#FFFFFF",
                width: 250,
                height: 33,
                fontWeight: 600,
                borderColor: "#FFFFFF",
                fontFamily: "Rubik",
              }}
              // onClick={() => }
              id="button-admin-go"
            >
              {t("confirmation.account.go.to.admin")}
            </Button>
          </a>
        </Box>
        <Box style={{ marginTop: 32, textAlign: "center" }}>
          <img src={imageAlreadyConfirmationAccount} alt="" />
        </Box>
      </Box>
    ) : tokenStatus?.dataResponse?.msg === consts.statys_token_not_found ? (
      <Navigate to="/easy/404" />
    ) : (
      <Box
        style={{
          height: "100vh",
          background:
            "linear-gradient(153.91deg, #E53463 0.87%, #4B27EF 83.55%)",
          overflow: "hidden",
          backgroundSize: "cover",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: height > 800 && "center",
          overflow: "scroll",
          paddingTop: height <= 800 ? 75 : 75,
          paddingBottom: height <= 800 && 75,
        }}
      >
        <Box
          style={{
            fontFamily: "Rubik",
            fontWeight: 400,
            fontSize: 40,
            color: "#FFFFFF",
          }}
        >
          {t("confirmation.account.succesful")}
        </Box>
        <Box
          style={{
            fontFamily: "Rubik",
            marginTop: 18,
            fontWeight: 500,
            fontSize: height >= 1180 ? 28 : 20,
            color: "#FFFFFF",
          }}
        >
          {t("confirmation.account.start")}
        </Box>
        <Box
          style={{
            fontFamily: "Rubik",
            marginTop: 7,
            fontSize: 12,
            color: "#FFFFFF",
          }}
        >
          {t("confirmation.account.from.here")}
        </Box>
        <Box
          style={{
            //   fontFamily: "Rubik",
            marginTop: 17,
            //   fontSize: 12,
            //   color: "#FFFFFF",
          }}
        >
          <Button
            variant="outlined"
            style={{
              textTransform: "capitalize",
              borderRadius: 20,
              fontSize: 14,
              color: "#EA345F",
              width: 250,
              height: 33,
              background: "#FFFFFF",
              fontWeight: 600,
              borderColor: "#FFFFFF",
              fontFamily: "Rubik",
            }}
            onClick={() => onContinue()}
            id="button-go"
          >
            {t("confirmation.account.begin")}
          </Button>
        </Box>
        <Box
          style={{
            fontFamily: "Rubik",
            marginTop: 80,
            fontSize: 20,
            color: "#FFFFFF",
            fontWeight: 500,
          }}
        >
          {t("confirmation.account.go.admin")}
        </Box>
        <Box
          style={{
            fontFamily: "Rubik",
            marginTop: 7,
            fontSize: 12,
            color: "#FFFFFF",
          }}
        >
          {t("confirmation.account.skip")}
        </Box>
        <Box
          style={{
            //   fontFamily: "Rubik",
            marginTop: 17,
            //   fontSize: 12,
            //   color: "#FFFFFF",
          }}
        >
          <a href={environment.adminURL} style={{ textDecoration: "none" }}>
            <Button
              variant="outlined"
              style={{
                textTransform: "initial",
                borderRadius: 20,
                fontSize: 14,
                color: "#FFFFFF",
                width: 250,
                height: 33,
                fontWeight: 600,
                borderColor: "#FFFFFF",
                fontFamily: "Rubik",
                fontSize: 14,
              }}
              id="button-admin-go"
            >
              {t("confirmation.account.go.to.admin")}
            </Button>
          </a>
        </Box>
        <Box
          style={{
            marginTop: 100,
          }}
        >
          <img
            src={imageConfirmationAccount}
            alt=""
            style={{ width: "40%", maxWidth: 600 }}
          />
        </Box>
        <Box style={{ left: "2.5%", position: "absolute", bottom: "2.5%" }}>
          <img src={companyLogoWhite} style={{ maxWidth: "263px" }} alt="" />
        </Box>
      </Box>
    )
  ) : tokenStatus?.dataResponse?.msg === consts.status_token_already_exist ? (
    <Box
      style={{
        height: "100vh",
        background: "linear-gradient(153.91deg, #E53463 0.87%, #4B27EF 83.55%)",
        overflow: "hidden",
        backgroundSize: "cover",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: 16,
      }}
    >
      <Box
        style={{
          fontFamily: "Rubik",
          fontWeight: 400,
          fontSize: 40,
          color: "#FFFFFF",
          textAlign: "center",
        }}
      >
        {t("notification.account.already.exist")}
      </Box>
      <Box
        style={{
          //   fontFamily: "Rubik",
          marginTop: 17,
          //   fontSize: 12,
          //   color: "#FFFFFF",
        }}
      >
        <a href={environment.adminURL} style={{ textDecoration: "none" }}>
          <Button
            variant="outlined"
            style={{
              textTransform: "initial",
              borderRadius: 20,
              fontSize: 14,
              color: "#FFFFFF",
              width: 250,
              height: 33,
              fontWeight: 600,
              borderColor: "#FFFFFF",
              fontFamily: "Rubik",
            }}
            id="button-admin-go"
          >
            {t("confirmation.account.go.to.admin")}
          </Button>
        </a>
      </Box>
      <Box style={{ marginTop: 32 }}>
        <img
          src={imageAlreadyConfirmationAccount}
          alt=""
          style={{
            width: mobileBreakpoint
              ? "100%"
              : width < 1024
              ? "100%"
              : height < 1366
              ? "40%"
              : "100%",
          }}
        />
      </Box>
    </Box>
  ) : tokenStatus?.dataResponse?.msg === consts.statys_token_not_found ? (
    <Navigate to="/easy/404" />
  ) : (
    <Box
      style={{
        height: "100vh",
        background: "linear-gradient(153.91deg, #E53463 0.87%, #4B27EF 83.55%)",
        overflow: "hidden",
        backgroundSize: "cover",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        // justifyContent: height > 800 && "center",
        overflow: "scroll",
        paddingTop: height <= 800 ? 75 : 75,
        paddingBottom: height <= 800 && 75,
      }}
    >
      <Box
        style={{
          fontFamily: "Rubik",
          fontWeight: 400,
          fontSize: height > 1023 || width > 1023 ? 63 : 40,
          color: "#FFFFFF",
        }}
      >
        {t("confirmation.account.succesful")}
      </Box>
      <Box
        style={{
          fontFamily: "Rubik",
          marginTop: 18,
          fontWeight: 500,
          fontSize: height > 1023 || width > 1023 ? 32 : 20,
          color: "#FFFFFF",
        }}
      >
        {t("confirmation.account.start")}
      </Box>
      <Box
        style={{
          fontFamily: "Rubik",
          marginTop: 7,
          fontSize: height > 1023 || width > 1023 ? 20 : 12,
          color: "#FFFFFF",
        }}
      >
        {t("confirmation.account.from.here")}
      </Box>
      <Box
        style={{
          //   fontFamily: "Rubik",
          marginTop: 17,
          //   fontSize: 12,
          //   color: "#FFFFFF",
        }}
      >
        <Button
          variant="outlined"
          style={{
            textTransform: "capitalize",
            borderRadius: height > 1023 || width > 1023 ? 40 : 20,
            fontSize: height > 1023 || width > 1023 ? 20 : 14,
            color: "#EA345F",
            width: 250,
            height: height > 1023 || width > 1023 ? 60 : 33,
            background: "#FFFFFF",
            fontWeight: 600,
            borderColor: "#FFFFFF",
            fontFamily: "Rubik",
          }}
          onClick={() => onContinue()}
          id="button-go"
        >
          {t("confirmation.account.begin")}
        </Button>
      </Box>
      <Box
        style={{
          fontFamily: "Rubik",
          marginTop: 80,
          fontSize: height > 1023 || width > 1023 ? 32 : 20,
          color: "#FFFFFF",
          fontWeight: 500,
        }}
      >
        {t("confirmation.account.go.admin")}
      </Box>
      <Box
        style={{
          fontFamily: "Rubik",
          marginTop: 7,
          fontSize: height > 1023 || width > 1023 ? 20 : 12,
          color: "#FFFFFF",
        }}
      >
        {t("confirmation.account.skip")}
      </Box>
      <Box
        style={{
          //   fontFamily: "Rubik",
          marginTop: 17,
          //   fontSize: 12,
          //   color: "#FFFFFF",
        }}
      >
        <a href={environment.adminURL} style={{ textDecoration: "none" }}>
          <Button
            variant="outlined"
            style={{
              textTransform: "initial",
              borderRadius: height > 1023 || width > 1023 ? 40 : 20,
              fontSize: height > 1023 || width > 1023 ? 20 : 14,
              color: "#FFFFFF",
              width: 250,
              height: height > 1023 || width > 1023 ? 60 : 33,
              fontWeight: 600,
              borderColor: "#FFFFFF",
              fontFamily: "Rubik",
            }}
            id="button-admin-go"
          >
            {t("confirmation.account.go.to.admin")}
          </Button>
        </a>
      </Box>
      <Box
        style={{
          marginTop: 100,
        }}
      >
        <img
          src={imageConfirmationAccount}
          alt=""
          style={{
            width: height >= 1180 ? "100%" : "40%",
            maxWidth: 600,
          }}
        />
      </Box>
      <Box style={{ left: "2.5%", position: "absolute", bottom: "2.5%" }}>
        <img src={companyLogoWhite} style={{ maxWidth: "263px" }} alt="" />
      </Box>
    </Box>
  );

  async function validateToken(token) {
    try {
      const data = await service.validateToken(token);
      setTokenStatus(data);
    } catch (err) {
      console.log("[Err validate token] =>", err);
      setTokenStatus(err);
    }
  }

  async function onContinue() {
    const userEmail = await service.getEmailByToken(params.token);
    localStorage.setItem("user_token", userEmail.token);
    const user = await service.getUser(userEmail.email);
    await setUser(user[0]);
    navigate("/contact/config");
  }
}

export default ConfirmationAccount;
