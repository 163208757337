import Box from "@mui/material/Box";
import endImage from "../assets/end.png";
import background from "../assets/backgroundConfirmationAccount.png";
import logoVideolink from "../assets/logo_videolink_mobile.svg";
import companyLogoWhite from "../assets/svg/omniflou-white.svg";
import imageConfirmationAccount from "../assets/imageConfirmationAccount.png";

function End() {
  return (
    <Box
      style={{
        height: "100vh",
        background: "linear-gradient(153.91deg, #E53463 0.87%, #4B27EF 83.55%)",
        overflow: "hidden",
        backgroundSize: "cover",
        display: "flex",
        flexDirection: "column",
        justifyContent: window.innerHeight > 800 && "center",
        overflow: "scroll",
        alignItems: "center",
        paddingTop: window.innerHeight <= 800 ? 150 : 24,
        paddingBottom: window.innerHeight <= 800 && 150,
      }}
    >
      <img src={logoVideolink} alt="" />
      <Box
        style={{
          fontFamily: "Rubik",
          marginTop: 51,
          fontWeight: 600,
          fontSize: 20,
          color: "#FFFFFF",
          textAlign: "center",
        }}
      >
        ¡Felicitaciones!
      </Box>
      <Box
        style={{
          marginTop: 100,
        }}
      >
        <img
          src={imageConfirmationAccount}
          alt=""
          style={{ width: "100%", maxWidth: 600 }}
        />
      </Box>
    </Box>
  );
}

export default End;
